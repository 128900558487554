/* Home.css */

body {
    transition: background-color 0.2s ease-in-out;
}

.section {
    transition: background-color 0.2s ease-in-out;
    background: transparent;
    position: relative;
}
.overflow-wrapper {
    overflow-y: hidden;
}

.hero-section, .curiosity-section, .services-section, .latest-news-section, .contact-us-section {
    background: transparent;
}

.figure-overlay {
    position: absolute;
    top: 20%;
    right: -10%;
    z-index: -1;
    max-width: 800px;
    opacity: 0.9;
    transition: max-width 0.3s ease-in-out;
    clip-path: inset(0 0 0 0); /* This ensures it stays within the viewport width */

}


@media (min-width: 1024px) {
    .figure-overlay {
        max-width: 1100px;
    }

    .hero-section .container {
        margin-left: 10%;
    }
}
